<template>
  <!-- Login -->
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row no-gutters style="min-height:80vh;" v-if="!$route.params.code">
        <b-col class="my-auto" md="6" offset-md="3">
          <h3 class="font-weight-bold text-center">Sign In</h3>
          <label for="text-email">Email*</label>
          <b-form @submit="authenticate">
            <b-form-input
              id="text-email"
              v-model="email"
              type="email"
              required
              placeholder=""
            ></b-form-input>
            <label for="text-password" class="mt-3">Password*</label>
            <b-form-input
              type="password"
              id="text-password"
              v-model="password"
              aria-describedby="password-help-block"
              required
            ></b-form-input>
            <p class=" mt-3 small" id="error">{{ error }}</p>
            <button class="btn font-weight-bold mt-3 w-100 shadow-sm">
              <span v-if="!this.loading">
                Sign In
              </span>
              <b-spinner
                small
                v-if="this.loading"
                type="grow"
                style="vertical-align:middle;"
              ></b-spinner>
            </button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";

export default {
  name: "Login",
  components: {
    Nav,
  },
  data: function() {
    return {
      email: "",
      password: "",
      error: "",
      loading: false,
    };
  },
  methods: {
    authenticate: function(e) {
      e.preventDefault();
      this.loading = true;
      this.$store
        .dispatch("login", { email: this.email, password: this.password })
        .then((response) => {
          console.log(response);
          this.$store.dispatch("getUser").then((response) => {
            console.log(response);
            this.$router.push("/");
            this.loading = false;
          });
        })
        .catch((error) => {
          this.error = error.response.data.detail + ". Please try again.";
          this.password = "";
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 2rem !important;
}
</style>
